<template>
  <div class="pb-4" style="background-color: #fff" id="page-pendaftaran">
    <b-container fluid>
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; font-size: 22px; margin-bottom: 0" class="custom-title-color-black">
              Statistik Prolanis (proses)
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>
      <b-row class="m-0 p-0">
        <b-col cols="6">
          <label for="jenis_kelamin">Jenis Kelamin</label>
          <Multiselect
            id="jenis_kelamin"
            v-model="dataFilter.jenis_kelamin"
            :options="listJenisKelamin"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="text"
            track-by="value"
            placeholder="-- Pilih Jenis Kelamin --"
            size="sm"
          ></Multiselect>
        </b-col>
        <b-col cols="6">
          <label for="ms_diagnosa_id">Diagnosa</label>
          <Multiselect
            id="ms_diagnosa_id"
            v-model="dataFilter.ms_diagnosa_id"
            :options="listDiagnosa"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="nama"
            track-by="ms_diagnosa_id"
            placeholder="-- Pilih Diagnosa --"
            size="sm"
          ></Multiselect>
        </b-col>
        <b-col cols="12">
          <div class="d-flex justify-content-end">
            <b-button 
              class="mr-2"
              :disabled="busy"
              @click="reset()"
              variant="danger"
            >Reset</b-button>
            <b-button
              :disabled="busy"
              @click="cari()"
              variant="success"
            >Cari</b-button>
          </div>
        </b-col>
        <b-col cols="6">
          <ByUmur v-if="!busy" :by_umur="by_umur" />
        </b-col>
        <b-col cols="6">
          <ByRiwayat v-if="!busy" :by_riwayat="by_riwayat" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import ByUmur from './by_umur.vue'
import ByRiwayat from './by_riwayat.vue'
export default {
  components:{
    ByUmur,
    ByRiwayat,
  },
  data() {
    return {
      busy: false,
      dataFilter: {
        jenis_kelamin: null,
      },
      listDiagnosa: [],
      listJenisKelamin:[
        { value: null , text: "Semua"},
        { value: "L" , text: "Laki - Laki"},
        { value: "P" , text: "Perempuan"},
      ],
      by_umur: [],
      by_riwayat: [],
    }
  },
  computed: {
    
  },
  activated() {
    const vm = this
    vm.getData()
    vm.reset()
  },
  methods: {
    async cari(){
      const vm = this
      vm.busy = false
      try {
        const res = await vm.$axios.post('/anamnesa/laporan_statistik_pasien_prolanis', {jenis_kelamin: vm.dataFilter.jenis_kelamin.value})
        // console.log('laporan_statistik_pasien', res)
        if(res.data.status == 200){
          // console.log('laporan_statistik_pasien', res.data.data)
          vm.by_umur = res.data.data[0].grafik_pasien_menurut_umur
          vm.by_riwayat = res.data.data[1].grafik_prolanis_menurut_riwayat
        }
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    async getData(){
      const vm = this
      vm.busy = false
      try {
        const listDiagnosa = await vm.$axios.post('/ms_diagnosa/list')
        vm.listDiagnosa = listDiagnosa.data.status == 200 ? listDiagnosa.data.data.map(x => {
          return {
            ...x,
            nama: `${x.kd_diag} - ${x.nm_diag}`,
          }
        }) : []
      } catch (error) {
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    reset(){
      const vm = this
      vm.dataFilter = {
        jenis_kelamin: vm.listJenisKelamin[0],
      }
      vm.cari()
    }
  },
}
</script>