<template>
  <div class="card p-3">
    <h5 class="custom-title-card"><strong>Grafik Pasien Prolanis Menurut Riwayat</strong></h5>
    <canvas id="chart-prolanis-riwayat" ref="serviks" style="height: 1000px; width: 100%"></canvas>
    <!-- <h6 class="text-right mt-2"><strong>Total {{by_riwayat.jumlah_total}} pasien</strong></h6> -->
    <div class="">
      <div class="d-flex justify-content-between">
        <div class="d-flex align-middle">
          <svg style="color: #38c172;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dice-1-fill" viewBox="0 0 16 16">
            <path d="M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3zm5 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
          </svg>
          <b class="ml-1">Diabetes</b></div>
        <div>{{by_riwayat.jumlah_diabetes}} ({{by_riwayat.persen_diabetes}}%)</div>
      </div>
      <div class="mt-1 d-flex justify-content-between">
        <div class="d-flex align-middle">
          <svg style="color: #3490dc;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dice-1-fill" viewBox="0 0 16 16">
            <path d="M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3zm5 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
          </svg>
          <b class="ml-1">Hypertense</b></div>
        <div>{{by_riwayat.jumlah_hypertense}} ({{by_riwayat.persen_hypertense}}%)</div>
      </div>
      <div class="mt-1 d-flex justify-content-between">
        <div class="d-flex align-middle">
          <svg style="color: #9561e2;" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dice-1-fill" viewBox="0 0 16 16">
            <path d="M3 0a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V3a3 3 0 0 0-3-3H3zm5 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
          </svg>
          <b class="ml-1">Diabetes - Hypertense</b></div>
        <div>{{by_riwayat.jumlah_bpjs}} ({{by_riwayat.jumlah_persen_bpjs}}%)</div>
      </div>
    </div>
  </div>
</template>

<script>
import {Chart, registerables } from 'chart.js'
Chart.register(...registerables );
export default {
  props: [
    'by_riwayat',
  ],
  data() {
    return {
      busy: false,
      myChart: null,
    }
  },
  computed: {
    
  },
  watch: {
    'by_riwayat': {
      handler(data) {
        // console.log('ubah dataKunjungan', data)
        data
        const vm = this
        vm.getData()
      },
      deep: true
    },
  },
  async activated() {
    const vm = this
    vm.myChart = new Chart(this.$refs.serviks, {
      type: 'doughnut',
      data: {
        labels: [
          'Diabetes',
          'Hypertense',
          'Diabetes - Hypertense',
        ],
        datasets: [{
          label: 'My First Dataset',
          data: [],
          backgroundColor: [
            // '#e3342f',
            // '#f6993f',
            // '#ffed4a',
            '#38c172',
            // '#4dc0b5',
            '#3490dc',
            // '#6574cd',
            '#9561e2',
            // '#f66d9b',
          ],
          hoverOffset: 4
        }]
      },
      options: {
        plugins: {
          legend: {
              display: false
          },
        }
      }
    })
    vm.myChart.update()
    // await vm.getData()
  },
  methods: {
    async getData(){
      const vm = this
      vm.busy = false
      try {
        // vm.myChart.destroy()
        console.log('by_riwayat', vm.by_riwayat)
        const data = []
        data.push(parseInt(vm.by_riwayat.jumlah_diabetes) || 0)
        data.push(parseInt(vm.by_riwayat.jumlah_hypertense) || 0)
        data.push(parseInt(vm.by_riwayat.jumlah_hypertense) || 0)
        // data.push(0)
        // data.push(0)
        // data.push(0)
        vm.myChart.data.datasets[0].data = data;
        vm.myChart.update()
      } catch (error) {
        vm.triggerAlert({ variant: "danger", msg: 'Terjadi kesalahan system', showing: true });
        console.log(error)
      } finally {
        vm.busy = false
      }
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
}
</script>